import React, { useEffect, useState } from "react";
import spacesStyles from "../../../styles/pages/spaces.module.scss";
import MenuContext from "antd/es/menu/MenuContext";
import {
  Button,
  Checkbox,
  Dropdown,
  MenuProps,
  notification,
  GetProp,
  Input,
  Menu,
  Select,
} from "antd";
import { AddFavourite } from "../../../services/contacts";
import VideoRoom from "../../call/VideoRoom";
import { StartCall } from "../../../services/calls";
import UserList from "../UsersCon/userList";
import { useQuery, useQueryClient } from "react-query";
import { FetchSpaces } from "../../../services/spaces";
import { EditGroup } from "../../../services/groups";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { showOldLog } from "../../../ConfigVar";
import Paragraph from "antd/es/typography/Paragraph";

const { Search } = Input;

type MenuItem = GetProp<MenuProps, "items">[number];

const insert = (
  list: MenuItem[],
  index: number,
  ...items: MenuItem[]
): MenuItem[] => {
  list.splice(index, 0, ...items);
  return list;
};

const ChatRooms = ({
  data,
  RoomId,
  setRoomId,
  setSubRoomId,
  groups,
  setUser,
  setContacts,
  Contacts,
  contact,
  startCall,
  privateChat,
  withLastMessage,
  isSpacesPage
}: any) => {
  const [selectedContacts, setSelectedContacts] = useState<
    Array<{ name: string; id: string }>
  >(Contacts ?? []);
  const [UserLoc, setUserLoc] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const [TOKEN, setTOKEN] = useState<string>("");
  const [CHANNEL, setCHANNEL] = useState<string>("");
  const [ID, setID] = useState<string>("");
  const [Loading, setLoading] = useState<boolean>(false);
  const [LoadingC, setLoadingC] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [callType, setCallType] = useState<string | number>(0);
  const location = useLocation();

  useEffect(() => { }, [useLocation()]);

  const handleChangeOpen = (open: boolean) => {
    setOpen(open);
  };

  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, [window.localStorage.getItem("user")]);

  const spaces = useQuery("Spaces", async () => FetchSpaces(), {
    cacheTime: 0,
    staleTime: 10000,
    enabled: groups && !privateChat,
  });

  const submitEditGroup = (user: any, id: any) => {
    setLoadingC(true);
    var form_data = new FormData();
    //TODO - not update all group info only `space_id`
    form_data.append("name", user.name);
    form_data.append("description", user.description);
    form_data.append("id", user.id);
    form_data.append("space_id", `${id}`);
    EditGroup(form_data)
      .then((res: any) => {
        setLoadingC(false);
        queryClient.invalidateQueries("Spaces");
        queryClient.invalidateQueries("groups");
        queryClient.invalidateQueries("groupsData");
      })
      .catch(() => {
        setLoadingC(false);
      });
  };

  const items = (user: any): MenuProps["items"] => {
    return [
      {
        label: "Favourite contacts",
        disabled: user?.favorite_by_me,
        onClick() {
          const form_data = new FormData();
          form_data.append("item_type", "1");
          form_data.append("item_id", `${user?.contact?.id}`);
          AddFavourite(form_data)
            .then((res) => {
              // queryClient.invalidateQueries("Contacts");
              notification.success({
                message: `${user?.contact?.full_name} added to favourite`,
              });
            })
            .catch(() => { });
        },
        key: "1",
        icon: (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.7276 3.44418L15.4874 6.99288C15.7274 7.48687 16.3673 7.9607 16.9073 8.05143L20.0969 8.58575C22.1367 8.92853 22.6167 10.4206 21.1468 11.8925L18.6671 14.3927C18.2471 14.8161 18.0172 15.6327 18.1471 16.2175L18.8571 19.3125C19.417 21.7623 18.1271 22.71 15.9774 21.4296L12.9877 19.6452C12.4478 19.3226 11.5579 19.3226 11.0079 19.6452L8.01827 21.4296C5.8785 22.71 4.57865 21.7522 5.13859 19.3125L5.84851 16.2175C5.97849 15.6327 5.74852 14.8161 5.32856 14.3927L2.84884 11.8925C1.389 10.4206 1.85895 8.92853 3.89872 8.58575L7.08837 8.05143C7.61831 7.9607 8.25824 7.48687 8.49821 6.99288L10.258 3.44418C11.2179 1.51861 12.7777 1.51861 13.7276 3.44418Z"
              stroke="#48484A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ),
      },
      {
        label: "Select",
        disabled: selectedContacts
          .map((c) => c.id)
          .includes(`${user?.contact?.id}`),
        onClick() {
          const newArr = [...selectedContacts];
          newArr.push({
            name: user?.contact?.full_name,
            id: `${user?.contact?.id}`,
          });
          setSelectedContacts([...newArr]);
          setContacts?.([...newArr]);
        },
        key: "2",
        icon: (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
              stroke="#48484A"
              stroke-width="1.5"
            />
            <path
              d="M8 12.5L10.5 15L16 9"
              stroke="#48484A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ),
      },
    ];
  };

  const chatItems = (user: any): MenuItem[] => {
    return [
      {
        key: "0-0",
        label: "Mute notification",
        icon: (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 22L2 2"
              stroke="#48484A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17 10C17.6296 10.7667 18 11.7054 18 12.7195C18 13.1635 17.929 13.593 17.7963 14"
              stroke="#48484A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20 8C21.2508 9.22951 22 10.7952 22 12.5C22 13.9164 21.4829 15.2367 20.5906 16.348"
              stroke="#48484A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14 14C14 17.1452 14 19.5313 13.074 19.9227C12.1481 20.3141 11.0583 19.2021 8.8787 16.9781C7.7499 15.8264 7.106 15.5713 5.5 15.5713C4.3879 15.5713 3.02749 15.7187 2.33706 14.6643C2 14.1496 2 13.4331 2 12C2 10.5669 2 9.85038 2.33706 9.33566C3.02749 8.28131 4.3879 8.42869 5.5 8.42869C6.60725 8.42869 7.3569 8.43869 7.96 7.96M14 9.5C14 6.3548 14.026 4.46866 13.1 4.0773C12.3292 3.75147 11.5323 4.46765 10 6"
              stroke="#48484A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ),
      },
      {
        key: "0-1",
        label: "Select",
        disabled: selectedContacts.map((c) => c.id).includes(`${user?.id}`),
        onClick() {
          const newArr = [...selectedContacts];
          newArr.push({
            name: user?.name,
            id: `${user?.id}`,
          });
          setSelectedContacts([...newArr]);
          setContacts?.([...newArr]);
        },
        icon: (
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 12.6667C22 7.1439 17.5228 2.66675 12 2.66675C6.47715 2.66675 2 7.1439 2 12.6667C2 18.1895 6.47715 22.6667 12 22.6667C17.5228 22.6667 22 18.1895 22 12.6667Z"
              stroke="#48484A"
              stroke-width="1.5"
            />
            <path
              d="M8 13.1667L10.5 15.6667L16 9.66675"
              stroke="#48484A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ),
      },
      {
        key: "0-3",
        label: "Delete chat",
        icon: (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5"
              stroke="#FF3B30"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5"
              stroke="#FF3B30"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M9.5 16.5V10.5"
              stroke="#FF3B30"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M14.5 16.5V10.5"
              stroke="#FF3B30"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
        ),
        danger: true,
        // style: { color: "red" },
      },
    ];
  };
  useEffect(() => {
    if (data.length > 0 && !Contacts && !contact) {
      if (privateChat) {
        if (showOldLog) {
          console.log("counter", data);
        }
        let counter = 0;
        data.map((privateData: any) => {
          counter = counter + privateData?.unread_messages_count;
        });
        if (showOldLog) {
          console.log("counter", counter);
        }
        localStorage.setItem("privateNotification", counter.toString());
      } else if (groups) {
        let counter = 0;
        data.map((groupData: any) => {
          counter = counter + groupData?.unread_messages_count;
          if (
            groupData?.staff_chat &&
            groupData?.staff_chat !== null &&
            UserLoc?.role !== "client"
          ) {
            counter = counter + groupData?.staff_chat?.unread_messages_count;
          }
        });
        if (counter)
          localStorage.setItem("groupsNotification", counter.toString());
      }
    }
  }, [data]);
  return (
    <Checkbox.Group
      style={{ width: "100%" }}
      value={selectedContacts.map((c) => [c.id, c.name].join(","))}
      onChange={(e) => {
        setSelectedContacts(
          e.map((value) => ({
            id: value.split(",")[0],
            name: value.split(",")[1],
          }))
        );
        setContacts?.(
          e.map((value) => ({
            id: value.split(",")[0],
            name: value.split(",")[1],
          }))
        );
      }}
    >
      <div className={spacesStyles.chatRooms}>
        {contact && (
          <>
            <div className={spacesStyles.chatItem}>
              <div style={{ flexGrow: 1 }}>
                <div
                  onClick={() => {
                    startCall({
                      calltype: "audio",
                      ids: selectedContacts.map((c) => c.id),
                    });
                  }}
                >
                  <div className={spacesStyles.favCon}>
                    <img src={"/images/audiocall.png"} alt="" />
                  </div>
                  <div className={spacesStyles.infoCon}>
                    <div className={spacesStyles.titleCon}>
                      <h4>New audio call</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={spacesStyles.chatItem}>
              <div style={{ flexGrow: 1 }}>
                <div
                  onClick={() => {
                    startCall({
                      calltype: "video",
                      ids: selectedContacts.map((c) => c.id),
                    });
                  }}
                >
                  <div className={spacesStyles.favCon}>
                    <img src={"/images/videocall.png"} alt="" />
                  </div>
                  <div className={spacesStyles.infoCon}>
                    <div className={spacesStyles.titleCon}>
                      <h4>New video call</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {data &&
          data.map((user: any, index: number) => (
            <>
              {((withLastMessage && user?.last_message?.content) ||
                !withLastMessage) && (
                  <div className={spacesStyles.chatItem}>
                    {(contact || groups) && selectedContacts.length > 0 && (
                      <Checkbox
                        value={`${contact
                            ? [user?.contact?.id, user?.contact?.full_name].join(
                              ","
                            )
                            : [user?.id, user?.name].join(",")
                          }`}
                      />
                    )}
                    <Dropdown
                      menu={contact ? { items: items(user) } : {}}
                      trigger={["contextMenu"]}
                      className={spacesStyles.dropdown}
                      disabled={!contact}
                    >
                      <div
                        className={
                          RoomId === (contact ? user?.contact?.id : user?.id)
                            ? spacesStyles.active
                            : ""
                        }
                      >
                        <div
                          onClick={() => {
                            if (!contact) {
                              setUser(user);
                              setRoomId(contact ? user?.contact?.id : user.id);
                              if (user?.staff_chat !== undefined)
                                setSubRoomId(user?.staff_chat?.id);
                              else setSubRoomId(0);
                            }
                          }}
                        >
                          <div className={spacesStyles.favCon}>
                            <img
                              src={
                                !groups
                                  ? user?.members?.find(
                                    (item: any) => item.id !== UserLoc.id
                                  )?.profile_image?.url
                                  : contact
                                    ? user?.contact?.profile_image?.url
                                    : user?.icon?.url
                              }
                              alt=""
                            />
                            {contact && user?.favorite_by_me && (
                              <div className={spacesStyles.fav}>
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="1.33333"
                                    y="1.33333"
                                    width="17.3333"
                                    height="17.3333"
                                    rx="8.66667"
                                    fill="#FFF0B3"
                                    stroke="white"
                                    stroke-width="1.33333"
                                  />
                                  <path
                                    d="M10.5943 6.15388L11.3542 7.68623C11.4578 7.89953 11.7342 8.10414 11.9673 8.14332L13.3446 8.37404C14.2254 8.52205 14.4327 9.16634 13.798 9.80191L12.7272 10.8815C12.5459 11.0643 12.4466 11.417 12.5027 11.6695L12.8093 13.0059C13.051 14.0637 12.494 14.473 11.5658 13.9201L10.2748 13.1496C10.0417 13.0103 9.65743 13.0103 9.41994 13.1496L8.129 13.9201C7.20503 14.473 6.64375 14.0594 6.88553 13.0059L7.19208 11.6695C7.24821 11.417 7.14891 11.0643 6.96756 10.8815L5.89681 9.80191C5.26644 9.16634 5.46937 8.52205 6.35015 8.37404L7.72746 8.14332C7.95629 8.10414 8.23262 7.89953 8.33624 7.68623L9.09613 6.15388C9.51062 5.32241 10.1841 5.32241 10.5943 6.15388Z"
                                    fill="#FFCC00"
                                    stroke="#FFCC00"
                                    stroke-width="0.647709"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                            )}
                          </div>
                          <div className={spacesStyles.infoCon}>
                            <div className={spacesStyles.titleCon}>
                              <h4 >
                                <span
                                className={`${isSpacesPage ? "uppercaseSpaces" : ""}`} 
                                >
                                {contact ? user?.contact?.full_name : user?.name}
                                </span>
                              </h4>
                              {!contact && (
                                <div className={spacesStyles.time}>
                                  {UserLoc?.role === "client"
                                    ? user?.last_message?.created_at?.split(
                                      "T"
                                    )[0]
                                    : UserLoc?.role === "staff"
                                      ? user?.staff_chat?.last_message?.created_at?.split(
                                        "T"
                                      )[0]
                                      : moment(user?.last_message?.created_at) >
                                        moment(
                                          user?.staff_chat?.last_message?.created_at
                                        )
                                        ? user?.last_message?.content
                                          ? user?.last_message?.created_at?.split(
                                            "T"
                                          )[0]
                                          : user?.staff_chat?.last_message?.created_at?.split(
                                            "T"
                                          )[0]
                                        : user?.staff_chat?.last_message?.content
                                          ? user?.staff_chat?.last_message?.created_at?.split(
                                            "T"
                                          )[0]
                                          : user?.last_message?.created_at?.split(
                                            "T"
                                          )[0]}
                                </div>
                              )}

                              {location.pathname === "/contacts" ? (
                                <div>
                                  <Button
                                    className={spacesStyles.edit__user__btn}
                                    onClick={() => {
                                      setUser(user);
                                      setRoomId(
                                        contact ? user?.contact?.id : user.id
                                      );
                                      if (user?.staff_chat !== undefined)
                                        setSubRoomId(user?.staff_chat?.id);
                                      else setSubRoomId(0);
                                    }}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M22 11.5667C22 16.8499 17.5222 21.1334 12 21.1334C11.3507 21.1343 10.7032 21.0742 10.0654 20.9545C9.60633 20.8682 9.37678 20.8251 9.21653 20.8496C9.05627 20.8741 8.82918 20.9948 8.37499 21.2364C7.09014 21.9197 5.59195 22.161 4.15111 21.893C4.69874 21.2194 5.07275 20.4112 5.23778 19.5448C5.33778 19.0148 5.09 18.5 4.71889 18.1231C3.03333 16.4115 2 14.1051 2 11.5667C2 6.28357 6.47778 2 12 2C17.5222 2 22 6.28357 22 11.5667Z"
                                        stroke="#3A3A3C"
                                        stroke-width="1.5"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M11.9955 12H12.0045M15.991 12H16M8 12H8.00897"
                                        stroke="#3A3A3C"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </Button>
                                  <Button
                                    disabled={Loading}
                                    className={spacesStyles.edit__user__btn}
                                    onClick={() => {
                                      setLoading(true);
                                      const formData = new FormData();
                                      formData.append("is_video", `0`);
                                      formData.append(
                                        "user_ids[]",
                                        `${user?.contact?.id}`
                                      );
                                      // formData.append(
                                      //   "user_ids[]",
                                      //   `${
                                      //     JSON.parse(
                                      //       window.localStorage.getItem(
                                      //         "user"
                                      //       ) as string
                                      //     ).id
                                      //   }`
                                      // );
                                      StartCall(formData)
                                        .then((res: any) => {
                                          setLoading(false);
                                          setCallType(0);
                                          setTOKEN(res?.json.agora_access_token);
                                          setCHANNEL(
                                            res?.json.agora_channel_name
                                          );
                                          setID(res?.json.id);
                                          handleChangeOpen(true);
                                        })
                                        .catch((err) => {
                                          setLoading(false);
                                        });
                                    }}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M3.77762 11.9424C2.8296 10.2893 2.37185 8.93948 2.09584 7.57121C1.68762 5.54758 2.62181 3.57081 4.16938 2.30947C4.82345 1.77638 5.57323 1.95852 5.96 2.6524L6.83318 4.21891C7.52529 5.46057 7.87134 6.08139 7.8027 6.73959C7.73407 7.39779 7.26737 7.93386 6.33397 9.00601L3.77762 11.9424ZM3.77762 11.9424C5.69651 15.2883 8.70784 18.3013 12.0576 20.2224M12.0576 20.2224C13.7107 21.1704 15.0605 21.6282 16.4288 21.9042C18.4524 22.3124 20.4292 21.3782 21.6905 19.8306C22.2236 19.1766 22.0415 18.4268 21.3476 18.04L19.7811 17.1668C18.5394 16.4747 17.9186 16.1287 17.2604 16.1973C16.6022 16.2659 16.0661 16.7326 14.994 17.666L12.0576 20.2224Z"
                                        stroke="#3A3A3C"
                                        stroke-width="1.5"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M14 6.83185C15.4232 7.43624 16.5638 8.57677 17.1682 10M14.654 2C18.1912 3.02076 20.9791 5.80852 22 9.34563"
                                        stroke="#3A3A3C"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                      />
                                    </svg>
                                  </Button>

                                  <Button
                                    disabled={Loading}
                                    className={spacesStyles.edit__user__btn}
                                    onClick={() => {
                                      setLoading(true);
                                      const formData = new FormData();
                                      formData.append("is_video", `1`);
                                      formData.append(
                                        "user_ids[]",
                                        `${user?.contact?.id}`
                                      );
                                      // formData.append(
                                      //   "user_ids[]",
                                      //   `${
                                      //     JSON.parse(
                                      //       window.localStorage.getItem(
                                      //         "user"
                                      //       ) as string
                                      //     ).id
                                      //   }`
                                      // );
                                      StartCall(formData)
                                        .then((res: any) => {
                                          setLoading(false);
                                          setCallType(1);
                                          setTOKEN(res?.json.agora_access_token);
                                          setCHANNEL(
                                            res?.json.agora_channel_name
                                          );
                                          setID(res?.json.id);
                                          handleChangeOpen(true);
                                        })
                                        .catch((err) => {
                                          setLoading(false);
                                        });
                                    }}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M2 11C2 7.70017 2 6.05025 3.02513 5.02513C4.05025 4 5.70017 4 9 4H10C13.2998 4 14.9497 4 15.9749 5.02513C17 6.05025 17 7.70017 17 11V13C17 16.2998 17 17.9497 15.9749 18.9749C14.9497 20 13.2998 20 10 20H9C5.70017 20 4.05025 20 3.02513 18.9749C2 17.9497 2 16.2998 2 13V11Z"
                                        stroke="#3A3A3C"
                                        stroke-width="1.5"
                                      />
                                      <path
                                        d="M11 8H13"
                                        stroke="#3A3A3C"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                      />
                                      <path
                                        d="M17 8.90585L17.1259 8.80196C19.2417 7.05623 20.2996 6.18336 21.1498 6.60482C22 7.02628 22 8.42355 22 11.2181V12.7819C22 15.5765 22 16.9737 21.1498 17.3952C20.2996 17.8166 19.2417 16.9438 17.1259 15.198L17 15.0941"
                                        stroke="#3A3A3C"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                      />
                                    </svg>
                                  </Button>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            {!contact && (
                              <div className={spacesStyles.desCon}>
                                <h5>
                                  <Paragraph
                                    ellipsis={{
                                      rows: 1,
                                      expandable: false,
                                      symbol: "...",
                                      tooltip: true
                                    }}
                                    style={{ maxWidth: "20ch" }}
                                  >
                                    {UserLoc?.role === "client"
                                      ? user?.last_message?.content
                                      : UserLoc?.role === "staff"
                                        ? user?.staff_chat?.last_message?.content
                                        : moment(user?.last_message?.created_at) >
                                          moment(
                                            user?.staff_chat?.last_message?.created_at
                                          )
                                          ? user?.last_message?.content
                                            ? user?.last_message?.content
                                            : user?.staff_chat?.last_message?.content
                                          : user?.staff_chat?.last_message?.content
                                            ? user?.staff_chat?.last_message?.content
                                            : user?.last_message?.content}
                                  </Paragraph>

                                </h5>
                                {user?.unread_messages_count +
                                  (user?.staff_chat?.unread_messages_count
                                    ? user?.staff_chat?.unread_messages_count
                                    : 0) !==
                                  0 && (
                                    <div className={spacesStyles.counter}>
                                      {user?.staff_chat?.unread_messages_count
                                        ? user?.unread_messages_count +
                                        user?.staff_chat?.unread_messages_count
                                        : user?.unread_messages_count}
                                    </div>
                                  )}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* {contact ? (
                    <></>
                  ) : (
                    <Menu
                      triggerSubMenuAction="click"
                      rootClassName={spacesStyles.optionMenu}
                      selectable={false}
                      items={[
                        {
                          key: "0",
                          expandIcon: (
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14 5C14 3.9 13.1 3 12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5Z"
                                fill="#0D0F11"
                              />
                              <path
                                d="M14 19C14 17.9 13.1 17 12 17C10.9 17 10 17.9 10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19Z"
                                fill="#0D0F11"
                              />
                              <path
                                d="M14 12C14 10.9 13.1 10 12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12Z"
                                fill="#0D0F11"
                              />
                            </svg>
                          ),
                          children: insert(
                            chatItems(user),
                            2,
                            privateChat && {
                              key: "0-2",
                              label: "User info",
                              onClick() {
                                setUser(user);
                                setRoomId(user.id + "open");
                                if (user?.staff_chat !== undefined)
                                  setSubRoomId(user?.staff_chat?.id);
                                else setSubRoomId(0);
                              },
                              icon: (
                                <svg
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2.5 12.333C2.5 7.85467 2.5 5.6155 3.89124 4.22425C5.28249 2.83301 7.52166 2.83301 12 2.83301C16.4783 2.83301 18.7175 2.83301 20.1088 4.22425C21.5 5.6155 21.5 7.85467 21.5 12.333C21.5 16.8113 21.5 19.0505 20.1088 20.4418C18.7175 21.833 16.4783 21.833 12 21.833C7.52166 21.833 5.28249 21.833 3.89124 20.4418C2.5 19.0505 2.5 16.8113 2.5 12.333Z"
                                    stroke="#48484A"
                                    stroke-width="1.5"
                                  />
                                  <path
                                    d="M7.5 17.333C9.8317 14.8908 14.1432 14.7758 16.5 17.333M14.4951 9.83301C14.4951 11.2137 13.3742 12.333 11.9915 12.333C10.6089 12.333 9.48797 11.2137 9.48797 9.83301C9.48797 8.4523 10.6089 7.33301 11.9915 7.33301C13.3742 7.33301 14.4951 8.4523 14.4951 9.83301Z"
                                    stroke="#48484A"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              ),
                            }
                            // : {
                            //     key: "0-2",
                            //     label: "Add to space",
                            //     icon: (
                            //       <svg
                            //         width="24"
                            //         height="25"
                            //         viewBox="0 0 24 25"
                            //         fill="none"
                            //         xmlns="http://www.w3.org/2000/svg"
                            //       >
                            //         <path
                            //           d="M18 13.8333V21.8333M22 17.8333H14"
                            //           stroke="#48484A"
                            //           stroke-width="1.5"
                            //           stroke-linecap="round"
                            //         />
                            //         <path
                            //           d="M7 6.83325H16.75C18.8567 6.83325 19.91 6.83325 20.6667 7.33884C20.9943 7.55772 21.2755 7.83897 21.4944 8.16654C21.9587 8.86144 21.9963 10.0174 21.9994 11.8333M12 6.83325L11.3666 5.56638C10.8418 4.51683 10.3622 3.46037 9.19926 3.02426C8.6899 2.83325 8.10802 2.83325 6.94427 2.83325C5.1278 2.83325 4.21956 2.83325 3.53806 3.21357C3.05227 3.48467 2.65142 3.88552 2.38032 4.37131C2 5.05281 2 5.96105 2 7.77752V10.8333C2 15.5473 2 17.9044 3.46447 19.3688C4.8215 20.7259 6.94493 20.8254 11 20.8327"
                            //           stroke="#48484A"
                            //           stroke-width="1.5"
                            //           stroke-linecap="round"
                            //         />
                            //       </svg>
                            //     ),
                            //     expandIcon: (
                            //       <svg
                            //         width="16"
                            //         height="17"
                            //         viewBox="0 0 16 17"
                            //         fill="none"
                            //         xmlns="http://www.w3.org/2000/svg"
                            //       >
                            //         <path
                            //           d="M5.93987 14.1133C5.81321 14.1133 5.68654 14.0666 5.58654 13.9666C5.49355 13.8725 5.44141 13.7456 5.44141 13.6133C5.44141 13.481 5.49355 13.354 5.58654 13.2599L9.93321 8.91327C10.2532 8.59327 10.2532 8.07327 9.93321 7.75327L5.58654 3.4066C5.49355 3.31251 5.44141 3.18555 5.44141 3.05326C5.44141 2.92098 5.49355 2.79402 5.58654 2.69993C5.77987 2.5066 6.09987 2.5066 6.29321 2.69993L10.6399 7.0466C10.9799 7.3866 11.1732 7.8466 11.1732 8.33327C11.1732 8.81993 10.9865 9.27993 10.6399 9.61993L6.29321 13.9666C6.19321 14.0599 6.06654 14.1133 5.93987 14.1133Z"
                            //           fill="#1C1C1E"
                            //         />
                            //       </svg>
                            //     ),
                            //     children: [
                            //       {
                            //         key: "0-2-0",
                            //         label: (
                            //           <UserList
                            //             ids={[`${user?.space_id}`]}
                            //             data={spaces?.data?.json}
                            //             loading={Loading}
                            //             onSubmit={(e) => {
                            //               submitEditGroup(user, e);
                            //             }}
                            //           />
                            //         ),
                            //         style: {
                            //           height: "auto",
                            //           padding: "10px",
                            //           width: "300px",
                            //           cursor: "default",
                            //         },
                            //         disabled: true,
                            //       },
                            //     ],
                            //   }
                          ),
                        },
                      ]}
                    />
                  )} */}
                      </div>
                    </Dropdown>
                  </div>
                )}
            </>
          ))}
      </div>
      {open ? (
        <>
          <VideoRoom
            TOKEN={TOKEN}
            CHANNEL={CHANNEL}
            IncomingCallInfo={ID}
            isModalOpen={open}
            handleChangeOpen={handleChangeOpen}
            callType={callType}
          />
        </>
      ) : (
        <></>
      )}
    </Checkbox.Group>
  );
};

export default ChatRooms;
