import React, { useContext, useEffect, useState } from "react";

import AgoraRTC from "agora-rtc-sdk-ng";
import { VideoPlayer } from "./VideoPlayer";
import { FetchCallsInfo, RejectCall, StartCall } from "../../services/calls";
import { Button, Col, Modal, Row, Drawer, Avatar } from "antd";
import stylescall from "../../styles/pages/call.module.scss";
import { CallContext, CallContextEnd } from "../../App";
import { userInfo } from "os";
import { showOldLog } from "../../ConfigVar";
const VideoRoom = ({
  TOKEN,
  CHANNEL,
  IncomingCallInfo,
  handleChangeOpen,
  isModalOpen,
  callType,
}: any) => {
  const [APP_ID, setAPP_ID] = useState<string>(
    "399320fa62ba4c6093a2941f565c7613"
  );
  const [isCameraEnabled, setIsCameraEnabled] = useState(true); // State to track camera status
  const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState(true);
  const [users, setUsers] = useState<any>([]);
  const [uid, setUid] = useState<any>(null);
  const [First, setFirst] = useState<number>(0);
  const [Tracks, setTracks] = useState<any>(null);
  const [UsersInfo, setUsersInfo] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [Client, setClient] = useState<any>(null);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  AgoraRTC.setLogLevel(4);
  var client = AgoraRTC.createClient({
    mode: "rtc",
    codec: "vp8",
  });

  let agoraCommandQueue = Promise.resolve();
  let tracks: any;
  const theCallInfo = useContext<any>(CallContext);

  // In order to handle change in state of the calls
  useEffect(() => {
    if (showOldLog) {
      console.log(JSON.parse(window.localStorage.getItem("user") as string).id);
    }

    FetchCallsInfo(IncomingCallInfo).then((res) => {
      if (showOldLog) {
        console.log(res.json, "here users");
      }
      // Extract usernames from the caller object
      const callerUsernames = [res.json.caller];

      // Extract usernames from the receivers array
      const receiverUsernames = res.json.receivers.map(
        (receiver: any) => receiver
      );

      // Combine both arrays into a single array of all usernames
      const allUsernames = [...callerUsernames, ...receiverUsernames];
      setUsersInfo(allUsernames);
    });
    if (showOldLog) {
      console.log("-----------");
      console.log(UsersInfo);
      console.log(users);
      console.log("-----------");
    }

  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (Client) {
        if (users.length === 1) {
          if (isCameraEnabled) {
          } else {
            toggleCamera();
          }
          RejectCall(`${IncomingCallInfo}`);
          cleanup();
          toggleCamera();
          handleChangeOpen(false);
          document.title = "H1Communicator";
        }
      }
    }, 30000); // 30 seconds

    return () => clearTimeout(timer);
  }, [users]);

  useEffect(() => {
    if (users.length === 1 && theCallInfo.finishCall) {
      // here is the function
      RejectCall(`${IncomingCallInfo}`);
      if (isCameraEnabled) {
      } else {
        toggleCamera();
      }
      RejectCall(`${IncomingCallInfo}`);
      cleanup();
      toggleCamera();
      handleChangeOpen(false);
    }
  }, [theCallInfo]);

  const waitForConnectionState = (connectionState: any) => {
    return new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (client) {
          if (client.connectionState === connectionState) {
            clearInterval(interval);
            resolve();
          }
        }
      }, 200);
    });
  };

  const connect = async () => {
    await waitForConnectionState("DISCONNECTED");

    const uid = await client
      .join(
        APP_ID,
        CHANNEL,
        TOKEN,
        JSON.parse(window.localStorage.getItem("user") as string).id
      )
      .then((res) => {
        if (res !== null) {
          setUid(res);
        }
      });

    client.on("user-published", (user: any, mediaType: any) => {
      client.subscribe(user, mediaType).then(() => {
        onVideoTrack(user);
        setClient(client);
      });
    });

    client.on("user-left", (user: any) => {
      onUserDisconnected(user);
      setClient(client);
    });

    tracks = await AgoraRTC.createMicrophoneAndCameraTracks();
    await client.publish(tracks).then(() => {
      setTracks(tracks);
      if (showOldLog) {
        console.log(client);
      }
    });
    setClient(client);

    return {
      tracks,
    };
  };

  const disconnect = async () => {
    client = Client;

    if (client) {
      client.removeAllListeners();
      for (let track of Tracks) {
        track.stop();
        track.close();
      }
      await client.unpublish(tracks);
      await client.leave();
    }
  };

  const onVideoTrack = (user: any) => {
    const checkUidExistence = (obj: any, uid: any) => {
      // Iterate through the object to find if any entry matches the provided uid
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key].uid === uid) {
          return true; // If found, return true
        }
      }
      return false; // If not found, return false
    };

    if (showOldLog) {
      console.log(checkUidExistence(users, user.uid), "bla bla"); // Output: true
    }


    setUsers((previousUsers: any) => [...previousUsers, user]);
    setClient(client);
    if (showOldLog) {
      console.log(users, "haha");
    }

  };

  const onUserDisconnected = (user: any) => {
    setUsers((previousUsers: any) =>
      previousUsers.filter((u: any) => u.uid !== user.uid)
    );
    setClient(client);
    if (showOldLog) {
      console.log(UsersInfo, "iii");
    }

    setUsersInfo((previousUsersInfo: any) =>
      previousUsersInfo.filter((u: any) => u.uid !== user.uid)
    );
    if (showOldLog) {
      console.log(UsersInfo, "iii");
    }
  };

  const setup = async () => {
    const { tracks } = await connect();

    setUsers((previousUsers: any) => [
      ...previousUsers,
      {
        uid: uid
          ? uid
          : JSON.parse(window.localStorage.getItem("user") as string).id,
        audioTrack: tracks[0],
        videoTrack: tracks[1],
      },
    ]);
    setClient(client);
  };

  const cleanup = async () => {
    setUid(null);
    const users: any = [];
    setUsers(users);
    console.log(users);
    await disconnect();
  };

  useEffect(() => {
    agoraCommandQueue = agoraCommandQueue.then(setup);

    return () => {
      // cleanup();
      agoraCommandQueue = agoraCommandQueue.then(cleanup);
    };
  }, []);

  const toggleCamera = () => {
    if (client) {
      setIsCameraEnabled((prevStatus) => !prevStatus);
      setFirst(1);
    }
  };

  const toggleMicrophone = () => {
    if (client) {
      setIsMicrophoneEnabled((prevStatus) => !prevStatus);
      setFirst(1);
    }
  };

  useEffect(() => {
    if (callType == 1) {
      setIsCameraEnabled(true);
    } else {
      setIsCameraEnabled(false);
    }
  }, []);

  return (
    <>
      <Modal
        open={isModalOpen}
        className={stylescall.call__Modall}
        footer={null}
        closable={false}
      >
        <Row>
          {Array.from(new Set(users)).length === 1 &&
            Array.from(new Set(users)).map((user: any, index: any) => (
              <Col className="gutter-row" span={24}>
                <VideoPlayer
                  key={user.uid}
                  user={user}
                  enableCamera={isCameraEnabled}
                  enableMicrophone={isMicrophoneEnabled}
                  First={First}
                  callType={callType}
                  users={users}
                  UsersInfo={UsersInfo}
                  heightOfVideo={500}
                />
              </Col>
            ))}

          {Array.from(new Set(users)).length === 2 &&
            Array.from(new Set(Array.from(new Set(users)))).map(
              (user: any, index: any) => (
                <Col className="gutter-row" span={12}>
                  <VideoPlayer
                    key={user.uid}
                    user={user}
                    enableCamera={isCameraEnabled}
                    enableMicrophone={isMicrophoneEnabled}
                    First={First}
                    callType={callType}
                    users={users}
                    UsersInfo={UsersInfo}
                    heightOfVideo={500}
                  />
                </Col>
              )
            )}

          {Array.from(new Set(users)).length === 3 && (
            <>
              <Col className="gutter-row" span={16}>
                <VideoPlayer
                  key={users[0].uid}
                  user={users[0]}
                  enableCamera={isCameraEnabled}
                  enableMicrophone={isMicrophoneEnabled}
                  First={First}
                  callType={callType}
                  users={users}
                  UsersInfo={UsersInfo}
                  heightOfVideo={500}
                />
              </Col>

              <Col span={8}>
                <VideoPlayer
                  key={users[1].uid}
                  user={users[1]}
                  enableCamera={isCameraEnabled}
                  enableMicrophone={isMicrophoneEnabled}
                  First={First}
                  callType={callType}
                  users={users}
                  UsersInfo={UsersInfo}
                  heightOfVideo={250}
                />
                <VideoPlayer
                  key={users[2].uid}
                  user={users[2]}
                  enableCamera={isCameraEnabled}
                  enableMicrophone={isMicrophoneEnabled}
                  First={First}
                  callType={callType}
                  users={users}
                  UsersInfo={UsersInfo}
                  heightOfVideo={250}
                />
              </Col>
            </>
          )}

          {Array.from(new Set(users)).length === 4 && (
            <>
              <Col className="gutter-row" span={16}>
                <VideoPlayer
                  key={users[0].uid}
                  user={users[0]}
                  enableCamera={isCameraEnabled}
                  enableMicrophone={isMicrophoneEnabled}
                  First={First}
                  callType={callType}
                  users={users}
                  UsersInfo={UsersInfo}
                  heightOfVideo={500}
                />
              </Col>

              <Col span={8}>
                <VideoPlayer
                  key={users[1].uid}
                  user={users[1]}
                  enableCamera={isCameraEnabled}
                  enableMicrophone={isMicrophoneEnabled}
                  First={First}
                  callType={callType}
                  users={users}
                  UsersInfo={UsersInfo}
                  heightOfVideo={166.6}
                />
                <VideoPlayer
                  key={users[2].uid}
                  user={users[2]}
                  enableCamera={isCameraEnabled}
                  enableMicrophone={isMicrophoneEnabled}
                  First={First}
                  callType={callType}
                  users={users}
                  UsersInfo={UsersInfo}
                  heightOfVideo={166.6}
                />

                <VideoPlayer
                  key={users[3].uid}
                  user={users[3]}
                  enableCamera={isCameraEnabled}
                  enableMicrophone={isMicrophoneEnabled}
                  First={First}
                  callType={callType}
                  users={users}
                  UsersInfo={UsersInfo}
                  heightOfVideo={166.6}
                />
              </Col>
            </>
          )}

          {Array.from(new Set(users)).length > 4 &&
            Array.from(new Set(users)).map((user: any) => (
              <>
                <Col className="gutter-row" span={12}>
                  <VideoPlayer
                    key={user.uid}
                    user={user}
                    enableCamera={isCameraEnabled}
                    enableMicrophone={isMicrophoneEnabled}
                    First={First}
                    callType={callType}
                    users={users}
                    UsersInfo={UsersInfo}
                    heightOfVideo={500}
                  />
                </Col>
              </>
            ))}
        </Row>

        <div className={stylescall.call__bar__container}>
          <div className={stylescall.call__bar}>
            <Button onClick={toggleMicrophone}>
              {isMicrophoneEnabled ? (
                <div>
                  <svg
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="54" height="54" rx="27" fill="#F2F2F7" />
                    <path
                      d="M32 22V26C32 28.7614 29.7614 31 27 31C24.2386 31 22 28.7614 22 26V22C22 19.2386 24.2386 17 27 17C29.7614 17 32 19.2386 32 22Z"
                      stroke="#1C1C1E"
                      stroke-width="1.5"
                    />
                    <path
                      d="M32 22H29M32 26H29"
                      stroke="#1C1C1E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M35 26C35 30.4183 31.4183 34 27 34M27 34C22.5817 34 19 30.4183 19 26M27 34V37M27 37H30M27 37H24"
                      stroke="#1C1C1E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              ) : (
                <div>
                  <svg
                    width="55"
                    height="54"
                    viewBox="0 0 55 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      width="54"
                      height="54"
                      rx="27"
                      fill="#FF3B30"
                    />
                    <path
                      d="M17.5 17L37.5 37"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M19.5 26C19.5 30.4183 23.0817 34 27.5 34M27.5 34C29.4545 34 31.2454 33.2991 32.6348 32.1348M27.5 34V37M27.5 37H30.5M27.5 37H24.5M35.5 26C35.5 27.6514 34.9996 29.1859 34.1422 30.4603"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M32.5078 21.9956C32.5078 19.2342 30.2692 17.0156 27.5078 17.0156C25.639 17.0156 24.0379 17.9809 23.1798 19.4996M32.5078 21.9956L29.4798 22.0076M32.5078 21.9956V26.0036M32.5078 26.0036V26.0156C32.5078 26.8465 32.3051 27.5701 31.9465 28.2596M32.5078 26.0036H29.6829M22.5078 21.9956V26.0156C22.5078 28.777 24.7464 31.0156 27.5078 31.0156C28.6432 31.0156 29.6725 30.6252 30.5118 29.9876"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              )}

              <div>Microphone</div>
            </Button>

            <Button onClick={toggleCamera}>
              {isCameraEnabled ? (
                <div>
                  <svg
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="54" height="54" rx="27" fill="#F2F2F7" />

                    <path
                      d="M26 23H28"
                      stroke="#1C1C1E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M17 26C17 22.7002 17 21.0502 18.0251 20.0251C19.0503 19 20.7002 19 24 19H25C28.2998 19 29.9497 19 30.9749 20.0251C32 21.0502 32 22.7002 32 26V28C32 31.2998 32 32.9497 30.9749 33.9749C29.9497 35 28.2998 35 25 35H24C20.7002 35 19.0503 35 18.0251 33.9749C17 32.9497 17 31.2998 17 28V26Z"
                      stroke="#1C1C1E"
                      stroke-width="1.5"
                    />
                    <path
                      d="M32 23.9059L32.1259 23.802C34.2417 22.0562 35.2996 21.1834 36.1498 21.6048C37 22.0263 37 23.4236 37 26.2181V27.7819C37 30.5765 37 31.9737 36.1498 32.3952C35.2996 32.8166 34.2417 31.9438 32.1259 30.198L32 30.0941"
                      stroke="#1C1C1E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              ) : (
                <div>
                  <svg
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="54" height="54" rx="27" fill="#FF3B30" />
                    <path
                      d="M17.5 17L37.5 37"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M26 23H28"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M17 26C17 22.7002 17 21.0502 18.0251 20.0251C19.0503 19 20.7002 19 24 19H25C28.2998 19 29.9497 19 30.9749 20.0251C32 21.0502 32 22.7002 32 26V28C32 31.2998 32 32.9497 30.9749 33.9749C29.9497 35 28.2998 35 25 35H24C20.7002 35 19.0503 35 18.0251 33.9749C17 32.9497 17 31.2998 17 28V26Z"
                      stroke="white"
                      stroke-width="1.5"
                    />

                    <path
                      d="M32 23.9059L32.1259 23.802C34.2417 22.0562 35.2996 21.1834 36.1498 21.6048C37 22.0263 37 23.4236 37 26.2181V27.7819C37 30.5765 37 31.9737 36.1498 32.3952C35.2996 32.8166 34.2417 31.9438 32.1259 30.198L32 30.0941"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              )}

              <div>Camera</div>
            </Button>

            <Button onClick={showDrawer}>
              <div>
                <svg
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="54" height="54" rx="27" fill="#F2F2F7" />
                  <path
                    d="M35.774 33C36.5233 33 37.1193 32.5285 37.6545 31.8691C38.7499 30.5194 36.9513 29.4408 36.2654 28.9126C35.568 28.3756 34.7894 28.0714 34 28M33 26C34.3807 26 35.5 24.8807 35.5 23.5C35.5 22.1193 34.3807 21 33 21"
                    stroke="#1C1C1E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M18.226 33C17.4767 33 16.8807 32.5285 16.3455 31.8691C15.2501 30.5194 17.0487 29.4408 17.7346 28.9126C18.432 28.3756 19.2106 28.0714 20 28M20.5 26C19.1193 26 18 24.8807 18 23.5C18 22.1193 19.1193 21 20.5 21"
                    stroke="#1C1C1E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M23.0838 30.1112C22.062 30.743 19.383 32.0331 21.0147 33.6474C21.8118 34.436 22.6995 35 23.8156 35H30.1844C31.3005 35 32.1882 34.436 32.9853 33.6474C34.617 32.0331 31.938 30.743 30.9162 30.1112C28.5201 28.6296 25.4799 28.6296 23.0838 30.1112Z"
                    stroke="#1C1C1E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M30.5 22.5C30.5 24.433 28.933 26 27 26C25.067 26 23.5 24.433 23.5 22.5C23.5 20.567 25.067 19 27 19C28.933 19 30.5 20.567 30.5 22.5Z"
                    stroke="#1C1C1E"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
              <div>Participants</div>
            </Button>

            <Button
              onClick={async () => {
                if (Client) {
                  if (isCameraEnabled) {
                  } else {
                    toggleCamera();
                  }
                  cleanup();
                  RejectCall(`${IncomingCallInfo}`);
                  handleChangeOpen(false);
                  document.title = "H1Communicator";
                }
              }}
            >
              <div>
                <svg
                  width="104"
                  height="54"
                  viewBox="0 0 104 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="104" height="54" rx="27" fill="#FF3B30" />
                  <path
                    d="M43.7776 26.9424C42.8296 25.2893 42.3718 23.9395 42.0958 22.5712C41.6876 20.5476 42.6218 18.5708 44.1694 17.3095C44.8234 16.7764 45.5732 16.9585 45.96 17.6524L46.8332 19.2189C47.5253 20.4606 47.8713 21.0814 47.8027 21.7396C47.7341 22.3978 47.2674 22.9339 46.334 24.006L43.7776 26.9424ZM43.7776 26.9424C45.6965 30.2883 48.7078 33.3013 52.0576 35.2224M52.0576 35.2224C53.7107 36.1704 55.0605 36.6282 56.4288 36.9042C58.4524 37.3124 60.4292 36.3782 61.6905 34.8306C62.2236 34.1766 62.0415 33.4268 61.3476 33.04L59.7811 32.1668C58.5394 31.4747 57.9186 31.1287 57.2604 31.1973C56.6022 31.2659 56.0661 31.7326 54.994 32.666L52.0576 35.2224Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>End call</div>
            </Button>
          </div>
        </div>
        <Drawer
          title="Call participants"
          onClose={onClose}
          open={open}
          getContainer={false}
        >
          <div className={stylescall.groups}>
            <div className={stylescall.groups__participants}>
              {UsersInfo.length} Participants
            </div>

            <div className={stylescall.chatRooms}>
              {UsersInfo &&
                UsersInfo.map((user: any, index: number) => (
                  <div>
                    {user?.profile_image?.url ? (
                      <Avatar
                        src={user?.profile_image?.url}
                        alt=""
                        style={{ marginRight: "5px" }}
                      ></Avatar>
                    ) : (
                      <Avatar size={44} style={{ marginRight: "5px" }}>
                        {user?.username?.slice(0, 2)}{" "}
                      </Avatar>
                    )}

                    <div className={stylescall.infoCon}>
                      <div className={stylescall.titleCon}>
                        <h4>{user?.username}</h4>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Drawer>
      </Modal>
    </>
  );
};
export default VideoRoom;
