import { AxiosRequestConfig } from "axios";
import ApiService from "../../utils/api/api-service";
import ApiResponse, { isError } from "../../utils/api/models/api-response";
import { ApiErrorNotification } from "../../utils/ui/notificationService";
import { REACT_APP_API_BASE_URL } from "../../ConfigVar";

class GroupsService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: REACT_APP_API_BASE_URL,
      ...config,
    });
  }

  public async Fetch(page: number): Promise<ApiResponse<any>> {
    return this.get(`/get-messages?page=${page}`);
  }
  public async FetchGroups(
    page: number,
    space: any,
    search: string
  ): Promise<ApiResponse<any>> {
    return this.get(
      space !== -1
        ? `/chat/group/all?page=${page}${
            space !== 0 && space !== -1
              ? "&space_ids[]=" + space + "&parent_only=1"
              : ""
          }${search !== "" ? "&criteria=" + search : ""}`
        : `/chat/group/unlisted`
    );
  }
  async Add(data: any): Promise<ApiResponse<any>> {
    return this.post(`/chat/group/store`, data);
  }
  async Edit(data: any): Promise<ApiResponse<any>> {
    return this.post(`/chat/group/update`, data);
  }
  async UpdatePerm(data: any): Promise<ApiResponse<any>> {
    return this.post(`/chat/member/manage`, data);
  }
}

const groupsService = new GroupsService();

export const FetchMessages = async (page: number) => {
  const result: any = await groupsService.Fetch(page);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const FetchGroups = async (page: number, space: any, search: string) => {
  const result: any = await groupsService.FetchGroups(page, space, search);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const postGroup = async (data: any) => {
  const result: any = await groupsService.Add(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const EditGroup = async (data: any) => {
  const result: any = await groupsService.Edit(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const UpdatePerm = async (data: any) => {
  const result: any = await groupsService.UpdatePerm(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
