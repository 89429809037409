import React, { useEffect, useState } from "react";
import spacesStyles from "../../../styles/pages/spaces.module.scss";
import ChatRooms from "../chatRooms";
import { Avatar, Checkbox, Dropdown, Input, Modal, Skeleton } from "antd";
import AddGroup from "../../spaces/addGroup";
import { CloseOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import { FetchContacts } from "../../../services/contacts";
import UserList from "./userList";
import { showOldLog } from "../../../ConfigVar";
import { debounce } from "../../../utils";
import dayjs from "dayjs";
const { Search } = Input;

const UsersCon = ({
  data,
  onFinish,
  RoomId,
  setRoomId,
  setSubRoomId,
  Loading,
  setLoading,
  setUser,
  group,
  setSearch,
  contact,
  withLastMessage,
  isSpacesPage
}: any) => {
  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);

  if (showOldLog) {
    console.log("data",data)
  }

  const [contactSearch, setContactSearch] = useState<string>("");

  type ISortType = "recent" | "a-z" | "z-a";

  const [sortType, setSortTypeStorage] = useState<ISortType>(
    (window.localStorage.getItem("contactSortType") as ISortType) ?? "a-z"
  );

  const setSortType = (e: ISortType) => {
    setSortTypeStorage(e);
    window.localStorage.setItem("contactSortType", e);
  };

  const [contactData, setContactData] = useState<any>(data);
  const [UserLoc, setUserLoc] = useState<any>({});
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, [window.localStorage.getItem("user")]);

  const Contacts = useQuery(["Contacts"], async () => FetchContacts(0), {
    cacheTime: 0,
    staleTime: 10000,
  });

  const sorting = (type: ISortType) => {
    if (data && contact) {
      const newArr = [...data];
      if (type === "a-z") {
        newArr.sort((a: any, b: any) =>
          a.contact.full_name.localeCompare(b.contact.full_name)
        );
      } else if (type === "z-a") {
        newArr.sort((a: any, b: any) =>
          b.contact.full_name.localeCompare(a.contact.full_name)
        );
      } else if (type === "recent") {
        if (UserLoc?.role === "client") {
          newArr.sort((a: any, b: any) => {
            const dateA = a?.user?.last_message ? dayjs(a.user.last_message.created_at) : dayjs(0);
            const dateB = b?.user?.last_message ? dayjs(b.user.last_message.created_at) : dayjs(0);
            return dateB.unix() - dateA.unix();
          });
        } else if (UserLoc?.role === "staff") {
          newArr.sort((a: any, b: any) => {
            const dateA = a?.staff_chat?.last_message || a?.user?.last_message ? 
              dayjs(a.staff_chat?.last_message?.created_at || a.user?.last_message?.created_at) : 
              dayjs(0);
            const dateB = b?.staff_chat?.last_message || b?.user?.last_message ? 
              dayjs(b.staff_chat?.last_message?.created_at || b.user?.last_message?.created_at) : 
              dayjs(0);
            return dateB.unix() - dateA.unix();
          });
        }
        // newArr.sort((a: any, b: any) => {
        //   const dateA = a?.staff_chat?.last_message ? dayjs(a.staff_chat?.last_message.created_at) : dayjs(0);
        //   const dateB = b?.staff_chat?.last_message ? dayjs(b.staff_chat?.last_message.created_at) : dayjs(0);
        //   return dateB.unix() - dateA.unix(); // Sort in descending order (most recent first)
        // });
      }
      setContactData([...newArr]);
    }
  };



  useEffect(() => {
    if (contact) {
      
      sorting(sortType);
    } else {
      if (showOldLog) {
        console.log("data",data)
      }
      if(data?.length > 0){
        const newArr = [...data];
        if (UserLoc?.role === "client") {
          newArr.sort((a: any, b: any) => {
            const dateA = a?.user?.last_message ? dayjs(a.user.last_message.created_at) : dayjs(0);
            const dateB = b?.user?.last_message ? dayjs(b.user.last_message.created_at) : dayjs(0);
            return dateB.unix() - dateA.unix();
          });
        } else if (UserLoc?.role === "staff") {
          newArr.sort((a: any, b: any) => {
            const dateA = a?.staff_chat?.last_message || a?.user?.last_message ? 
              dayjs(a.staff_chat?.last_message?.created_at || a.user?.last_message?.created_at) : 
              dayjs(0);
            const dateB = b?.staff_chat?.last_message || b?.user?.last_message ? 
              dayjs(b.staff_chat?.last_message?.created_at || b.user?.last_message?.created_at) : 
              dayjs(0);
            return dateB.unix() - dateA.unix();
          });
        }
        // newArr.sort((a: any, b: any) => {
        //   const dateA = a?.staff_chat?.last_message ? dayjs(a.staff_chat?.last_message.created_at) : dayjs(0);
        //   const dateB = b?.staff_chat?.last_message ? dayjs(b.staff_chat?.last_message.created_at) : dayjs(0);
        //   return dateB.unix() - dateA.unix(); // Sort in descending order (most recent first)
        // });
        setContactData(newArr);
      }else{
        setContactData(data);

      }
    
    }
  }, [data]);
  const handleNewGroup = () => {
    const createModal = Modal.info({
      className: "addspaceModal",
      title: "Create new group",
      width: "40%",
      centered: false,
      content: <AddGroup Contacts={Contacts} onFinish={onFinish} />,
      footer: [],
      icon: <></>,
      autoFocusButton: null,
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      maskClosable: true,
      closable: true,
    });
  };
  const handleNewMessage = () => {};

  const openNewCallModal = ({
    calltype,
    ids,
  }: {
    calltype: "audio" | "video";
    ids: Array<string>;
  }) => {
    const modal = Modal.info({
      className: "addspaceModal",
      centered: true,
      title: `Start ${calltype} call`,
      autoFocusButton: null,
      width: "40%",
      icon: <></>,

      content: (
        <UserList
          calltype={calltype}
          ids={ids}
          data={data.filter(((res:any)=>UserLoc?.id!==res?.contact?.id))}
          onCloseModal={() => modal.destroy()}
        />
      ),
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      maskClosable: true,
      closable: true,
      footer: [],
    });
  };

  const onChangeSearch = debounce((e: any) => {
    if (e && e !== "" && e?.length > 2) {
      if(contact){
        setContactSearch(e)
      }else{
        setSearch(e)
      }
    }
  }, 1200); // 2 seconds delay
  
  

  return (
    <div className={spacesStyles.groups}>
      <div className={spacesStyles.groupsHeader}>
        <h5 className={spacesStyles.title}>
          {group ? "Groups" : contact ? "Contacts" : "1-1 Chats"}
        </h5>
        {!contact ? (
          <button
            className={group ? "" : spacesStyles.red}
            onClick={group ? handleNewGroup : handleNewMessage}
          >
            {group ? (
              (UserLoc?.role === "owner" ||
                UserLoc?.role === "su" ||
                UserLoc?.role === "admin") && (
                <>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 8H12"
                      stroke="#555D68"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 12V4"
                      stroke="#555D68"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  New group
                </>
              )
            ) : (
              <>
                {/* <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 8H12"
                  stroke="url(#paint0_linear_2745_10703)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 12V4"
                  stroke="url(#paint1_linear_2745_10703)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2745_10703"
                    x1="12.36"
                    y1="7.93"
                    x2="12.104"
                    y2="10.1255"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#BF4034" />
                    <stop offset="1" stop-color="#9B1724" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2745_10703"
                    x1="9.045"
                    y1="3.44"
                    x2="6.70132"
                    y2="3.75407"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#BF4034" />
                    <stop offset="1" stop-color="#9B1724" />
                  </linearGradient>
                </defs>
              </svg>
              New message */}
              </>
            )}
          </button>
        ) : (
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                {
                  key: "a-z",
                  label: "Name ascending A-Z",
                  icon: (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 14H8.42109C9.35119 14 9.81624 14 9.94012 14.2801C10.064 14.5603 9.74755 14.8963 9.11466 15.5684L5.47691 19.4316C4.84402 20.1037 4.52757 20.4397 4.65145 20.7199C4.77533 21 5.24038 21 6.17048 21H10"
                        stroke="#48484A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4 9L6.10557 4.30527C6.49585 3.43509 6.69098 3 7 3C7.30902 3 7.50415 3.43509 7.89443 4.30527L10 9"
                        stroke="#48484A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.5 20V4M17.5 20C16.7998 20 15.4915 18.0057 15 17.5M17.5 20C18.2002 20 19.5085 18.0057 20 17.5"
                        stroke="#48484A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ),
                },
                {
                  key: "z-a",
                  label: "Name ascending Z-A",
                  icon: (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 3H8.42109C9.35119 3 9.81624 3 9.94012 3.28013C10.064 3.56026 9.74755 3.89632 9.11466 4.56842L5.47691 8.43158C4.84402 9.10368 4.52757 9.43974 4.65145 9.71987C4.77533 10 5.24038 10 6.17048 10H10"
                        stroke="#48484A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4 21L6.10557 16.3053C6.49585 15.4351 6.69098 15 7 15C7.30902 15 7.50415 15.4351 7.89443 16.3053L10 21"
                        stroke="#48484A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.5 20V4M17.5 20C16.7998 20 15.4915 18.0057 15 17.5M17.5 20C18.2002 20 19.5085 18.0057 20 17.5"
                        stroke="#48484A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ),
                },
                {
                  key: "recent",
                  label: "Most recent",
                  icon: (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.9767 19.5C19.4017 17.8876 21 15.1305 21 12C21 7.02944 16.9706 3 12 3C11.3126 3 10.6432 3.07706 10 3.22302M16.9767 19.5V16M16.9767 19.5H20.5M7 4.51555C4.58803 6.13007 3 8.87958 3 12C3 16.9706 7.02944 21 12 21C12.6874 21 13.3568 20.9229 14 20.777M7 4.51555V8M7 4.51555H3.5"
                        stroke="#48484A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ),
                },
              ],
              selectable: true,
              defaultSelectedKeys: [sortType],
              selectedKeys: [sortType],
              onSelect(info) {
                setSortType(info.key as ISortType);
                sorting(info.key as ISortType);
              },
            }}
          >
            <button className={spacesStyles.sortbyBtn}>
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 6.5L9.33333 6.50005"
                  stroke="#393E46"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2 10.5H6.66667"
                  stroke="#393E46"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2 2.5H12.6667"
                  stroke="#393E46"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.3333 14.5V6.5M12.3333 14.5C11.8665 14.5 10.9943 13.1705 10.6667 12.8333M12.3333 14.5C12.8001 14.5 13.6723 13.1705 14 12.8333"
                  stroke="#393E46"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Sort by
            </button>
          </Dropdown>
        )}
      </div>
      <div className={spacesStyles.searchCon}>
        <Search
          placeholder="Search..."
          allowClear={true}
          onChange={(e: any) =>
            e.target.value === "" ?
            (contact ? setContactSearch("") : setSearch(""))
            :
            onChangeSearch(e.target.value)
          }
          onSearch={(e: any) => (contact ? setContactSearch(e) : setSearch(e))}
          prefix={<img alt="searchIcon" src="/images/search.png" />}
        />
      </div>
      {Loading ? (
        <div className={spacesStyles.groupsSkeleton}>
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
        </div>
      ) : (
        <>
          {contactData?.length > 0 ? (
            <ChatRooms
            withLastMessage={withLastMessage}
              groups
              privateChat={!group}
              isSpacesPage={isSpacesPage}
              data={contactData?.filter((c: any) =>
                contact
                  ? c.contact.full_name
                      .toLowerCase()
                      .match(contactSearch.toLowerCase())
                  : true
              )}
              RoomId={RoomId}
              setRoomId={(Id: any) => {
                setRoomId(Id);
              }}
              setUser={(data: any) => {
                setUser(data);
              }}
              setSubRoomId={(Id: any) => setSubRoomId(Id)}
              contact={contact}
              startCall={(info: any) => {
                openNewCallModal({ calltype: info.calltype, ids: info.ids });
              }}
            />
          ) : (
            contactData?.length === 0 && (
              <div className={spacesStyles.emptypage}>
                <img src="/images/window.png" alt="" />
                <h4>{contact?"No contacts available":"No groups available"}</h4>
                <p>{contact?"You don't have any contacts.":"You haven't joined or created any groups."}</p>
                {(UserLoc?.role === "owner" ||
                  UserLoc?.role === "su" ||
                  UserLoc?.role === "admin") && (
                  <button onClick={group ? handleNewGroup : () => {}}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.00002 2.78882V13.211"
                        stroke="#636366"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.7889 8H13.2111"
                        stroke="#636366"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Create group
                  </button>
                )}
              </div>
            )
          )}
        </>
      )}
    </div>
  );
};

export default UsersCon;
